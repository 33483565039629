import React, {useState} from 'react'
// import '../../App.css'
import Modal from 'react-bootstrap/Modal'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'

import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

function ModalForm(props) {
    return (
      <>
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Using Grid in Modal
        </Modal.Title>
      </Modal.Header>
      <Modal.Body centered="true" className="show-grid">
        <Container>
        
        <Row>
        <Col xs={6} md={12}>
        -3 (mycket negativ påverkan) till +3 (mycket positiv påverkan). 0 är neutralt.</Col>
        </Row>
    
                   <Row>
           <Col xs={6} md={2}>
              
            </Col>
            <Col xs={10} md={1}>
               <Form>
                
                    </Form>
            </Col>
            <Col xs={10} md={1}>
               <Form>
                -3
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
               -2
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                  -1
                    </Form>
            </Col>
            <Col xs={10} md={1}>
                   0
            </Col>
            <Col xs={10} md={1}>
                  1
            </Col>
            <Col xs={10} md={1}>
                 2
            </Col>
            <Col xs={10} md={1}>
             3
            </Col>
            <Col xs={10} md={1}>
              <Form>
                Ingen åsikt
                    </Form>
            </Col>
   
            
          </Row>           <Row>
           <Col xs={6} md={2}>
              .col-xs-6 .col-md-4
            </Col>
            <Col xs={10} md={1}>
               <Form>
                
                    </Form>
            </Col>
            <Col xs={10} md={1}>
               <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            
          </Row>           <Row>
           <Col xs={6} md={2}>
              .col-xs-6 .col-md-4
            </Col>
                   <Col xs={10} md={1}>
               <Form>
                
                    </Form>
            </Col>
            
            <Col xs={10} md={1}>
               <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>

            
          </Row>           <Row>
           <Col xs={6} md={2}>
              .col-xs-6 .col-md-4
            </Col>
                   <Col xs={10} md={1}>
               <Form>
                
                    </Form>
            </Col>
            <Col xs={10} md={1}>
               <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            
          </Row>           <Row>
           <Col xs={6} md={2}>
              .col-xs-6 .col-md-4
            </Col>
                   <Col xs={10} md={1}>
               <Form>
                
                    </Form>
            </Col>
            <Col xs={10} md={1}>
               <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>

            
          </Row>           <Row>
           <Col xs={6} md={2}>
              .col-xs-6 .col-md-4
            </Col>
                   <Col xs={10} md={1}>
               <Form>
                
                    </Form>
            </Col>
            <Col xs={10} md={1}>
               <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>
            <Col xs={10} md={1}>
              <Form>
                {['radio'].map((type) => (
                  <div key={`custom-inline-${type}`} className="mb-3">
              
                    <Form.Check
                      custom
                      inline
                      type={type}
                      id={`custom-inline-${type}-1`}
                      name="group1"
                      label=""
                    />
                    </div>))}
                    </Form>
            </Col>

            
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>

 
      </>
    );
  }


export default ModalForm