import React, { useState, useEffect, useContext } from 'react';
import SignUp from './SignUp';
import LogIn from './LogIn';
import ForgotPassword from './ForgotPassword';
import Button from 'react-bootstrap/Button'
import { AccountContext } from './Accounts';


export default () => {
    const [pages, setPages] = useState({ loginPage: true, signupPage: false, forgotpasswordPage: false});
    const [loggedIn, setLoggedIn ] = useState(false);

    const { getSession } = useContext(AccountContext);
    
    useEffect(() => {
        getSession()
            .then(() => { setLoggedIn(true);
    })
            .catch(err =>console.log(err))      
    }, []);
    
 

    const onClickForgot = () =>{
        setPages({ loginPage: false, signupPage: false, forgotpasswordPage: true})
        console.log(pages.loginPage, pages.signupPage, pages.forgotpasswordPage)
    }

    const onClickLogin = () => {
        setPages({ loginPage: true, signupPage: false, forgotpasswordPage: false})
        console.log(pages.loginPage, pages.signupPage, pages.forgotpasswordPage)

    }
    const onClickSignup = () => {
        setPages({ loginPage: false, signupPage: true, forgotpasswordPage: false})
        console.log(pages.loginPage, pages.signupPage, pages.forgotpasswordPage)

    }

    let page = ''
    let buttons = ''
    if (pages.loginPage) {
        page = <LogIn/>
        buttons = <>       
         <Button size="sm" variant="outline-primary" onClick={event => onClickSignup()}>Registera</Button>{'  '}
<Button variant="link" size="sm" onClick={event => onClickForgot()}>Glömt lösenord</Button></>
    }
    else if (pages.signupPage) {
        page = <SignUp/>
        buttons = <><Button size="sm" variant="link" onClick={event => onClickLogin()} block>Logga in istället</Button></>
    }
    
    else if (pages.forgotpasswordPage) {
        page = <ForgotPassword/>
        buttons = <><Button size="sm"  variant="link" onClick={event => onClickLogin()} block> Tillbaka till inloggning</Button></>
    }

    return (
        <div className="article">
 
    {!loggedIn && (<>

        <div className="wrapper-header"><div className="wrapper-header-text">Byggprocessen.info</div></div>

  <div className="section-login">
    <div className="wrapper-login">

        {page}
        {buttons}
        </div>
        </div></>
    )}</div>
  
    )

}

