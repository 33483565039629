import React, { useState } from 'react';
import { Account } from './components/Accounts';
import SignUp from './components/SignUp';
import LogIn from './components/LogIn';
import ForgotPassword from './components/ForgotPassword';
import Status from './components/Status';
import Settings from './components/Settings';
import Attributes from './components/Attributes';
import LoginSignupForgot from './components/LoginSignupForgot';


import Anvandarvillkor from './components/Anvandarvillkor';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Personuppgiftspolicy from './components/Personuppgiftspolicy';
import XMark from './components/svg-saker/components/XMark';

export default () => {
  
return (
  <Account>
  <Status/>

<Router>
    <Switch>
      <Route exact path="/" render={()=>(<LoginSignupForgot/>)}/>
      </Switch>
      </Router>

    </Account>
)
}