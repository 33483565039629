import React, { useState, useEffect, useContext } from 'react';
import { AccountContext } from './Accounts';
import {
    CognitoUserPool,
    CognitoUserAttribute,
    CognitoUser,
} from 'amazon-cognito-identity-js';

import UserPool from '../UserPool'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory
} from "react-router-dom";

export default () => {
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ confirmPassword, setConfirmPassword ] = useState('');
  const [ firstname, setFirstname ] = useState('');
  const [ lastname, setLastname ] = useState('');
  const [ validCheck, setValidCheck ] = useState({ email: false, password: false, firstname: false, lastname: false, password: false, confirmPassword: false, checkPassword: false, checkbox:false })
  const [loggedIn, setLoggedIn ] = useState(false);
  const [ disabled, setDisabled ] = useState(true);
  const [ validPassword, setValidPassword] = useState('')
  const [ anErrorOccured, setAnErrorOccured ] = useState('')


  const { getSession } = useContext(AccountContext)

  let history = useHistory();




  useEffect(() => {
      getSession().then(data => {
          setLoggedIn(true)
      })
      .catch(err=> console.log(err))
  }, []);

  
  
  var attributeList = [];
  
  var dataFirstname = {
  
  Name: 'name',
  
  Value: firstname,
  
  };
  
  var dataLastname = {
  
  Name: 'family_name',
  
  Value: lastname,
  
  };

  var dataConsentPolicys = {
    
  Name: "website",
  Value: "Jag accepterar användarvillkoren och jag har tagit del av personuppgiftspolicyn."

}
  
  var attributeFirstname = new CognitoUserAttribute(dataFirstname);
  var attributeLastname = new CognitoUserAttribute(dataLastname);
  var attributeConsentPolicys = new CognitoUserAttribute(dataConsentPolicys);

  
  attributeList.push(attributeFirstname);
  
  attributeList.push(attributeLastname);
  
  attributeList.push(attributeConsentPolicys);





const ifChecked = (event) => {
		if (validCheck.checkbox) {
            setValidCheck({...validCheck, checkbox:false});
            setDisabled(true);
            return;
        } else {
            setValidCheck({...validCheck, checkbox:true});
            setDisabled(false);
            return;
        }
    }
    


const onChangeEmail =(event)=>{
    setEmail(event.target.value)
    if (email !== ""){
    setValidCheck({...validCheck, email:true})
    }
    else {
        setValidCheck({...validCheck, email:false})
    }
    console.log(validCheck)
}
const onChangeFirstName =(event)=>{
    setFirstname(event.target.value)
    if (firstname !== ""){
    setValidCheck({...validCheck, firstname:true})
    }
    else {
        setValidCheck({...validCheck, firstname:false})
    }
    console.log(validCheck)

}
const onChangeLastName =(event)=>{
    setLastname(event.target.value)
    if (lastname !== ""){
    setValidCheck({...validCheck, lastname:true})
    }
    else {
        setValidCheck({...validCheck, lastname:false})
    }
    console.log(validCheck)

}

const onChangePassword =(event)=>{
    setPassword(event.target.value)
    if (password !== ""){
    setValidCheck({...validCheck, password:true})
    }
    else {
        setValidCheck({...validCheck, password:false})
    }
    return
}
const onChangeConfirmPassword =(event)=>{
    setConfirmPassword(event.target.value)
    if (confirmPassword !== ""){
    setValidCheck({...validCheck, confirmPassword:true})
    }
    else {
        setValidCheck({...validCheck, confirmPassword:false})
    }
    // console.log(validCheck)
    return
} 


const onChangeCheckPassword =()=>{
    if (password !== confirmPassword){
        console.log('Password and confimed password are NOT the same')
        setValidPassword(<p style={{color:"darkred"}}>Lösenorden stämmer inte överrens.</p>)

        setValidCheck({...validCheck, checkPassword:false})


    }
    else if (password === confirmPassword) {
        console.log('Password and confimed password are the same')
        setValidPassword(<p style={{color:"green"}}>Lösenorden stämmer överrens.</p>)

        setValidCheck({...validCheck, checkPassword:true})
        

    }
    return

}



const onSubmit = event => {
  event.preventDefault();

  UserPool.signUp(email, password, attributeList, null, (err,data) => {
      
   if (err) {
       console.error(err);
       if (err.name === 'UsernameExistsException'){
        setAnErrorOccured(<p style={{color: "firebrick", fontSize:"0.8em"}}>Detta kontot är redan registrerat.</p>)

       }
       else{ 
        setAnErrorOccured(<p style={{color: "firebrick", fontSize:"0.8em"}}>Felaktigt inskrivna uppgifter/alla uppifter är inte ifyllda. Försök igen!</p>)
    }
    }

    else{
        console.log(data)
        setAnErrorOccured(<p style={{color: "Green", fontSize:"0.8em"}}>Ett mejl med verifikationslänk har skickats till emailadressen.</p>)
        setTimeout(() =>
               
        window.location.reload()
        , 1500);

    

}
})}

return (

  <div>
{!loggedIn && (
<Form onSubmit={onSubmit}>
<div className="wrapper-login-text">Registrera dig</div>
    <Form.Group>
<Form.Label>Förnamn <i style={{color:"red"}}>*</i></Form.Label><Form.Control onChange={onChangeFirstName} required/>
<Form.Label>Efternamn <i style={{color:"red"}}>*</i></Form.Label><Form.Control onChange={onChangeLastName} required/>
<Form.Label>Email <i style={{color:"red"}}>*</i></Form.Label><Form.Control value={email} onChange={onChangeEmail} required/>

</Form.Group>
<Form.Group>
<Form.Label>Lösenord <i style={{color:"red"}}>*</i><br/>(Måste innehålla: Minst 1 siffra, symbol (t ex . ; ! ? # ), en liten och stor bokstav. Totalt 6 tecken eller fler.)</Form.Label><Form.Control type="password" value={password} onChange={onChangePassword} required/>


</Form.Group>
<Form.Group controlId="formBasicCheckbox2">
<Form.Label></Form.Label>
<Form.Check required size="lg">
    <Form.Check.Input
    onChange={ifChecked}
        type="checkbox"
        
    />
    <Form.Check.Label
        type="checkbox"
        className="signup-checkbox"
        required
        size="md"
    >
        
        
        &nbsp;&nbsp; Jag accepterar <a href="/anvandarvillkor.html" target="_blank">användarvillkoren</a> och jag har tagit del av <a href="/personuppgiftspolicy.html" target="_blank">personuppgiftspolicyn</a>.<i style={{color:"red"}}>*</i>

									</Form.Check.Label>
								</Form.Check>
							</Form.Group>
{anErrorOccured}
<Button size="sm" disabled={disabled} type="submit" variant="outline-primary"block> Registera dig </Button>
</Form>)

 } </div>


)

}


{/* <Form.Label>Upprepa lösenord</Form.Label><Form.Control  type="password" value={confirmPassword} onChange={onChangeConfirmPassword} required/> */}