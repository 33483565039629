/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useContext } from "react";
// import "../../App.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ModalForm from "./ModalForm";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Alert from 'react-bootstrap/Alert';
import Container from "react-bootstrap/Container";
import Spinner from 'react-bootstrap/Spinner'
import ModalFormRowCheck from './ModalFormRowCheck';
import axios from 'axios'
import { AccountContext } from '../../../Accounts';

// import Amplify, { Auth } from 'aws-amplify';
import Draggable from 'react-draggable';
import ModalDialog from 'react-bootstrap/ModalDialog';

let arrayWIds= ['forsta_-3','forsta_-2','forsta_-1','forsta_0','forsta_1','forsta_2','forsta_3', 'forsta_ia','andra_-3','andra_-2','andra_-1','andra_0','andra_1','andra_2','andra_3','tredje_-3','tredje_-2','tredje_-1','tredje_0','tredje_1','tredje_2','tredje_3','fjarde_-3','fjarde_-2','fjarde_-1','fjarde_0','fjarde_1','fjarde_2','fjarde_3','femte_-3','femte_-2','femte_-1','femte_0','femte_1','femte_2','femte_3']



function ModalTest(props) {
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [ifNotValid, setIfNotValid] = useState("")
  const [auth, setAuth] = useState("")
  const [success, setSuccess] = useState("") 



  const [comment, setComment] = useState('');
  const [data, setData] = useState([{ sbeId: '', text: '', org: '', fas:'', typ:''}]);
  const [statusForsta, setStatusForsta] = useState({theTitle:'', upload_radio_min3: false, upload_radio_min2: false, upload_radio_min1: false, upload_radio_0: false, upload_radio_1: false, upload_radio_2: false, upload_radio_3: false, upload_radio_ia: false})
  const [statusAndra, setStatusAndra] = useState({theTitle:'', upload_radio_min3: false, upload_radio_min2: false, upload_radio_min1: false, upload_radio_0: false, upload_radio_1: false, upload_radio_2: false, upload_radio_3: false, upload_radio_ia: false})
  const [statusTredje, setStatusTredje] = useState({theTitle:'', upload_radio_min3: false, upload_radio_min2: false, upload_radio_min1: false, upload_radio_0: false, upload_radio_1: false, upload_radio_2: false, upload_radio_3: false, upload_radio_ia: false})
  const [statusFjarde, setStatusFjarde] = useState({theTitle:'', upload_radio_min3: false, upload_radio_min2: false, upload_radio_min1: false, upload_radio_0: false, upload_radio_1: false, upload_radio_2: false, upload_radio_3: false, upload_radio_ia: false})
  const [statusFemte, setStatusFemte] = useState({theTitle:'', upload_radio_min3: false, upload_radio_min2: false, upload_radio_min1: false, upload_radio_0: false, upload_radio_1: false, upload_radio_2: false, upload_radio_3: false, upload_radio_ia: false})

  const [loader, setLoader] = useState('')


  const [bedomning, setBedomning] = useState([{tidDelprocess:'', tidHelhet:'', kostnadDelprocess:'', kostnadHelhet: '', klimatpaverkan: ''}])
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  
// function App(){
const [activeDrags, setActiveDrags] = useState(0)

function onStart(){
setActiveDrags(activeDrags+1) 
}

function onStop(){
setActiveDrags(activeDrags-1) 
}

const dragHandlers = { onStart, onStop };
// return (
//   <div>
//     <Draggable {...dragHandlers}>
//       <div className="box">I can be dragged anywhere</div>
//     </Draggable>
//   </div>
// );
// };



  let strId = props.viewId
  strId = strId.substring(0, strId.length - 1);


const [ name, setName ] = useState("")

const { getSession, logout } = useContext(AccountContext);



  useEffect(() => {
    setLoader(<Spinner animation="border" variant="info" />)

    
    async function fetchData() {
      getSession()
      .then(session => {
        setAuth(session.email)
      })
      .catch(err => console.log(err))

    // Auth.currentUserInfo().then(user => {
          
    //   setAuth(user)

    // });

    const result = await axios(
      `https://iodqzdvwri.execute-api.eu-central-1.amazonaws.com/prod/object?sbeId=${strId}`,
      
    )
    .then(result=>{
      console.log(result)
      setData(result.data)
      setLoader('')
  
  
      setSuccess("")
      setIfNotValid("")
      setComment("")
      setStatusForsta({theTitle:'', upload_radio_min3: false, upload_radio_min2: false, upload_radio_min1: false, upload_radio_0: false, upload_radio_1: false, upload_radio_2: false, upload_radio_3: false, upload_radio_ia: false})
      setStatusAndra({theTitle:'', upload_radio_min3: false, upload_radio_min2: false, upload_radio_min1: false, upload_radio_0: false, upload_radio_1: false, upload_radio_2: false, upload_radio_3: false, upload_radio_ia: false})
       setStatusTredje({theTitle:'', upload_radio_min3: false, upload_radio_min2: false, upload_radio_min1: false, upload_radio_0: false, upload_radio_1: false, upload_radio_2: false, upload_radio_3: false, upload_radio_ia: false})
       setStatusFjarde({theTitle:'', upload_radio_min3: false, upload_radio_min2: false, upload_radio_min1: false, upload_radio_0: false, upload_radio_1: false, upload_radio_2: false, upload_radio_3: false, upload_radio_ia: false})
      setStatusFemte({theTitle:'', upload_radio_min3: false, upload_radio_min2: false, upload_radio_min1: false, upload_radio_0: false, upload_radio_1: false, upload_radio_2: false, upload_radio_3: false, upload_radio_ia: false})
      console.log(auth)})
    // console.log(result.data)

    .catch(err => console.log(err))

  }
 
  fetchData()
  
    



}, [strId]);

let headerInfo = ''
if (loader == ''){
  if (strId.toLowerCase() != strId.toUpperCase()){ // If it's a letter
    headerInfo = data[0].sbeId+"; "+data[0].text+", "+ data[0].org+ ", "+ data[0].fas + ", " + data[0].typ
  }
  else { // If it's a number
    headerInfo = data[0].sbeId+"; "+data[0].from+ " => "+data[0].to
  }
}
else {
  headerInfo = loader
}

function onBoxClick(e) {
  let targetName = e.currentTarget.name
  let targetId = e.currentTarget.id

  // if (targetName === 'group-$forsta'){
    if ('$forsta_-3' === targetId ){
    setStatusForsta({theTitle:"-3", upload_radio_min3: e.currentTarget.value })
    }
    else if ('$forsta_-2'  === targetId ){
      setStatusForsta({theTitle:"-2", upload_radio_min2: e.currentTarget.value })
    } 
    else if ('$forsta_-1'  === targetId ){
      setStatusForsta({theTitle:"-1", upload_radio_min1: e.currentTarget.value })

    }
    else if ('$forsta_0'  === targetId ){
      setStatusForsta({theTitle:"0", upload_radio_0: e.currentTarget.value })

    }
    else if ('$forsta_1'  === targetId ){
      setStatusForsta({theTitle:"1", upload_radio_1: e.currentTarget.value })

    }
    else if ('$forsta_2'  === targetId ){
      setStatusForsta({theTitle:"2", upload_radio_2: e.currentTarget.value })

    }
    else if ('$forsta_3'  === targetId ){
      setStatusForsta({theTitle:"3", upload_radio_3: e.currentTarget.value })

    }
    else if('$forsta_ia'  === targetId ){
      setStatusForsta({theTitle:"Ingen åsikt", upload_radio_ia: e.currentTarget.value })
    }
  
  


  // else if (targetName === 'group-andra'){
    if ('andra_-3' === targetId ){
      setStatusAndra({theTitle:"-3", upload_radio_min3: e.currentTarget.value })
      }
      else if ('andra_-2'  === targetId ){
        setStatusAndra({theTitle:"-2", upload_radio_min2: e.currentTarget.value })
      } 
      else if ('andra_-1'  === targetId ){
        setStatusAndra({theTitle:"-1", upload_radio_min1: e.currentTarget.value })
  
      }
      else if ('andra_0'  === targetId ){
        setStatusAndra({theTitle:"0", upload_radio_0: e.currentTarget.value })
  
      }
      else if ('andra_1'  === targetId ){
        setStatusAndra({theTitle:"1", upload_radio_1: e.currentTarget.value })
  
      }
      else if ('andra_2'  === targetId ){
        setStatusAndra({theTitle:"2", upload_radio_2: e.currentTarget.value })
  
      }
      else if ('andra_3'  === targetId ){
        setStatusAndra({theTitle:"3", upload_radio_3: e.currentTarget.value })
  
      }
      else if ('andra_ia'  === targetId){
        setStatusAndra({theTitle:"Ingen åsikt", upload_radio_ia: e.currentTarget.value })
      }

  // }

  // else if (targetName === 'group-tredje'){
    if ('tredje_-3' === targetId ){
      setStatusTredje({theTitle:"-3", upload_radio_min3: e.currentTarget.value })
      }
      else if ('tredje_-2'  === targetId ){
        setStatusTredje({theTitle:"-2", upload_radio_min2: e.currentTarget.value })
      } 
      else if ('tredje_-1'  === targetId ){
        setStatusTredje({theTitle:"-1", upload_radio_min1: e.currentTarget.value })
  
      }
      else if ('tredje_0'  === targetId ){
        setStatusTredje({theTitle:"0", upload_radio_0: e.currentTarget.value })
  
      }
      else if ('tredje_1'  === targetId ){
        setStatusTredje({theTitle:"1", upload_radio_1: e.currentTarget.value })
  
      }
      else if ('tredje_2'  === targetId ){
        setStatusTredje({theTitle:"2", upload_radio_2: e.currentTarget.value })
  
      }
      else if ('tredje_3'  === targetId ){
        setStatusTredje({theTitle:"3", upload_radio_3: e.currentTarget.value })
  
      }
      else if('tredje_ia'  === targetId ){
        setStatusTredje({theTitle:"Ingen åsikt", upload_radio_ia: e.currentTarget.value })
      }

  // }

  else if (targetName === 'group-fjarde'){
    if ('fjarde_-3' === targetId ){
      setStatusFjarde({theTitle:"-3", upload_radio_min3: e.currentTarget.value })
      }
      else if ('fjarde_-2'  === targetId ){
        setStatusFjarde({theTitle:"-2", upload_radio_min2: e.currentTarget.value })
      } 
      else if ('fjarde_-1'  === targetId ){
        setStatusFjarde({theTitle:"-1", upload_radio_min1: e.currentTarget.value })
  
      }
      else if ('fjarde_0'  === targetId ){
        setStatusFjarde({theTitle:"0", upload_radio_0: e.currentTarget.value })
  
      }
      else if ('fjarde_1'  === targetId ){
        setStatusFjarde({theTitle:"1", upload_radio_1: e.currentTarget.value })
  
      }
      else if ('fjarde_2'  === targetId ){
        setStatusFjarde({theTitle:"2", upload_radio_2: e.currentTarget.value })
  
      }
      else if ('fjarde_3'  === targetId ){
        setStatusFjarde({theTitle:"3", upload_radio_3: e.currentTarget.value })
  
      }
      else if('fjarde_ia'  === targetId ){
        setStatusFjarde({theTitle:"Ingen åsikt", upload_radio_ia: e.currentTarget.value })
      }

  }

  // else if (targetName === 'group-femte') {
    if ('femte_-3' === targetId ){
      setStatusFemte({theTitle:"-3", upload_radio_min3: e.currentTarget.value })
      }
      else if ('femte_-2'  === targetId ){
        setStatusFemte({theTitle:"-2", upload_radio_min2: e.currentTarget.value })
      } 
      else if ('femte_-1'  === targetId ){
        setStatusFemte({theTitle:"-1", upload_radio_min1: e.currentTarget.value })
  
      }
      else if ('femte_0'  === targetId ){
        setStatusFemte({theTitle:"0", upload_radio_0: e.currentTarget.value })
  
      }
      else if ('femte_1'  === targetId ){
        setStatusFemte({theTitle:"1", upload_radio_1: e.currentTarget.value })
  
      }
      else if ('femte_2'  === targetId ){
        setStatusFemte({theTitle:"2", upload_radio_2: e.currentTarget.value })
  
      }
      else if ('femte_3'  === targetId ){
        setStatusFemte({theTitle:"3", upload_radio_3: e.currentTarget.value })
  
      }
      else if('femte_ia'  === targetId ){
        setStatusFemte({theTitle:"Ingen åsikt", upload_radio_ia: e.currentTarget.value })
      }

  // }
}


  const handleSubmit = (event) => {
    if (statusForsta.theTitle === '' || statusAndra.theTitle === '' || statusTredje.theTitle === '' || statusFjarde.theTitle === '' || statusFemte.theTitle === '') {
      event.stopPropagation();
      event.preventDefault();

      setIfNotValid(<Alert variant='danger'><p style={{color:"darkRed", display: "flex", justifyContent: "center", marginBottom: "0"}}>Var god och fyll i alla bedömningar.</p></Alert>)


    }
    else {

      setIfNotValid("")

      var hasTrueKey1 = Object.keys(statusForsta).some(k => statusForsta[k]);
      var hasTrueKey2 = Object.keys(statusAndra).some(k => statusAndra[k]);
      var hasTrueKey3 = Object.keys(statusTredje).some(k => statusTredje[k]);
      var hasTrueKey4 = Object.keys(statusFjarde).some(k => statusFjarde[k]);
      var hasTrueKey5 = Object.keys(statusFemte).some(k => statusFemte[k]);

      event.preventDefault();
      // first, post to database
      // then...

      let newData = {
        "sbeId": strId,
        "tidDelprocess": statusForsta.theTitle,
        "tidHelhet": statusAndra.theTitle,
        "kostnadDelprocess" : statusTredje.theTitle,
        "kostnadHelhet" : statusFjarde.theTitle,
        "klimatpåverkan" : statusFemte.theTitle,
        "kommentar" : comment,
        "user" : auth
      }
      // console.log(newData)


           const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(newData)
          };
          fetch('https://iodqzdvwri.execute-api.eu-central-1.amazonaws.com/prod/assessment', requestOptions)
              .then(response => { response.json()} )
              .catch((error)=> console.log(error))
              // .then(data => this.setState({ postId: data.id }));

              setSuccess(<div className="success-box" style={{marginRight:'4em'}}><Alert variant="success" style={{borderColor: "#1faa3e"}}><p style={{color:"darkGreen", marginTop: "0", marginBottom:"0", marginLeft:"4em", marginRight: "4em"}}>Skickat!</p></Alert></div>)
              setComment('')

              setStatusForsta({theTitle:'', upload_radio_min3: false, upload_radio_min2: false, upload_radio_min1: false, upload_radio_0: false, upload_radio_1: false, upload_radio_2: false, upload_radio_3: false, upload_radio_ia: false})
              setStatusAndra({theTitle:'', upload_radio_min3: false, upload_radio_min2: false, upload_radio_min1: false, upload_radio_0: false, upload_radio_1: false, upload_radio_2: false, upload_radio_3: false, upload_radio_ia: false})
               setStatusTredje({theTitle:'', upload_radio_min3: false, upload_radio_min2: false, upload_radio_min1: false, upload_radio_0: false, upload_radio_1: false, upload_radio_2: false, upload_radio_3: false, upload_radio_ia: false})
               setStatusFjarde({theTitle:'', upload_radio_min3: false, upload_radio_min2: false, upload_radio_min1: false, upload_radio_0: false, upload_radio_1: false, upload_radio_2: false, upload_radio_3: false, upload_radio_ia: false})
              setStatusFemte({theTitle:'', upload_radio_min3: false, upload_radio_min2: false, upload_radio_min1: false, upload_radio_0: false, upload_radio_1: false, upload_radio_2: false, upload_radio_3: false, upload_radio_ia: false})
        }

        


    }


    // const form = event.currentTarget;
    // if (form.checkValidity() === false) {
    //     event.stopPropagation();
    // }

    // setValidated(true);
    // event.preventDefault();

    // axios.post(
    //     URL, {
    //         fornamn: fornamn,
    //         efternamn: efternamn,
    //         email: email,
    //         telefonnummer: telefonnummer,
    //         message: message,
    //         participate: participate,
    //         gdpr: "Jag accepterar att mitt namn och organisation finns med på en deltagarförteckning som delas ut i samband med evenemanget och att jag är synlig på den filmade versionen av webbinariet. Jag godkänner även att mina personuppgifter lagras och används för administration i samband med evenemanget.",
    //     }
    // );
// };
//     <Draggable {...dragHandlers}>

  return (
    <>
                <Modal style={{backgroundColor:"transparent"}} dialogClassName='custom-dialog'
        {...props}
        
        aria-labelledby="contained-modal-title-vcenter"
        show={props.show} onHide={props.handleClose}><Draggable {...dragHandlers}><div style={{backgroundColor:"white", borderRadius: "5px"}}>
          <Form
						onSubmit={handleSubmit}>   
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          {headerInfo}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body centered="true" className="show-grid">
          <Container style={{ justifySelf: "center", alignSelf: "center" }}>

            <Row>
              <Col xs={6} md={12}>
                <Alert variant="light"> -3 (mycket negativ påverkan) till +3 (mycket positiv påverkan).
                0 är neutralt.</Alert>
              </Col>
            </Row>
              {ifNotValid}
              <Row style={{ borderBottom: "1px solid whitesmoke"}}>
                <Col xs={6} md={2}></Col>
                <Col xs={10} md={1}></Col>
                <Col xs={10} md={1}>
                  -3
                </Col>
                <Col xs={10} md={1}>
                  -2
                </Col>
                <Col xs={10} md={1}>
                  -1
                </Col>
                <Col xs={10} md={1}>
                  0
                </Col>
                <Col xs={10} md={1}>
                  1
                </Col>
                <Col xs={10} md={1}>
                  2
                </Col>
                <Col xs={10} md={1}>
                  3
                </Col>
                <Col xs={10} md={1}>
                  Ingen åsikt
                </Col>
              </Row>
              <Form.Group required>
              <Row className="rowNew">
                <Col xs={6} md={3} >
                Tid<p className="rowDescText">(inom <i>aktuell fas</i>)</p>
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`$forsta_-3`}
                    name={`group-$forsta`}
                    label=""
                    checked={statusForsta.upload_radio_min3}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`$forsta_-2`}
                    name={`group-$forsta`}
                    label=""
                    checked={statusForsta.upload_radio_min2}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`$forsta_-1`}
                    name={`group-$forsta`}
                    label=""
                    checked={statusForsta.upload_radio_min1}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`$forsta_0`}
                    name={`group-$forsta`}
                    label=""
                    checked={statusForsta.upload_radio_0}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`$forsta_1`}
                    name={`group-$forsta`}
                    label=""
                    checked={statusForsta.upload_radio_1}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`$forsta_2`}
                    name={`group-$forsta`}
                    label=""
                    checked={statusForsta.upload_radio_2}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`$forsta_3`}
                    name={`group-$forsta`}
                    label=""
                    checked={statusForsta.upload_radio_3}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`$forsta_ia`}
                    name={`group-$forsta`}
                    label=""
                    checked={statusForsta.upload_radio_ia}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
              </Row>{" "}
            </Form.Group>{" "}
            <Form.Group required>
              <Row className="rowNew">
                <Col xs={6} md={3} >
                Tid<p className="rowDescText">(<i>"befintliga förhållanden"</i> <br/>t.o.m <i>"produktion"</i>)</p>
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`andra_-3`}
                    name={`group-andra`}
                    label=""
                    // name="upload_radio"
                    // checked={status.upload_radio}
                    // value="textarea"
                    // onChange={e => onBoxClick(e)}
                    checked={statusAndra.upload_radio_min3}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`andra_-2`}
                    name={`group-andra`}
                    label=""
                                        checked={statusAndra.upload_radio_min2}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`andra_-1`}
                    name={`group-andra`}
                    label=""
                                        checked={statusAndra.upload_radio_min1}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`andra_0`}
                    name={`group-andra`}
                    label=""
                                        checked={statusAndra.upload_radio_0}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`andra_1`}
                    name={`group-andra`}
                    label=""
                                        checked={statusAndra.upload_radio_1}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`andra_2`}
                    name={`group-andra`}
                    label=""
                                        checked={statusAndra.upload_radio_2}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`andra_3`}
                    name={`group-andra`}
                    label=""
                    checked={statusAndra.upload_radio_3}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`andra_ia`}
                    name={`group-andra`}
                    label=""
                                        checked={statusAndra.upload_radio_ia}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
              </Row>{" "}
            </Form.Group>{" "}
            <Form.Group required>
              <Row className="rowNew">
                <Col xs={6} md={3} >
                Kostnad<p className="rowDescText">(inom <i>aktuell fas</i>)</p>
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`tredje_-3`}
                    name={`group-tredje`}
                    label=""
                                        checked={statusTredje.upload_radio_min3}
                    onChange={e => onBoxClick(e)}
                    // name="upload_radio"
                    // checked={status.upload_radio}
                    // value="textarea"
                    // onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`tredje_-2`}
                    name={`group-tredje`}
                    label=""
                                        checked={statusTredje.upload_radio_min2}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`tredje_-1`}
                    name={`group-tredje`}
                    label=""
                                        checked={statusTredje.upload_radio_min1}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`tredje_0`}
                    name={`group-tredje`}
                    label=""
                                        checked={statusTredje.upload_radio_0}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`tredje_1`}
                    name={`group-tredje`}
                    label=""
                                        checked={statusTredje.upload_radio_1}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`tredje_2`}
                    name={`group-tredje`}
                    label=""
                                        checked={statusTredje.upload_radio_2}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`tredje_3`}
                    name={`group-tredje`}
                    label=""
                                        checked={statusTredje.upload_radio_3}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`tredje_ia`}
                    name={`group-tredje`}
                    label=""
                                        checked={statusTredje.upload_radio_ia}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
              </Row>{" "}
            </Form.Group>{" "}
            <Form.Group required>
              <Row className="rowNew">
                <Col xs={6} md={3} >
                Kostnad<p className="rowDescText">(<i>"befintliga förhållanden"</i> t.o.m. <i>"produktion"</i>)</p>
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`fjarde_-3`}
                    name={`group-fjarde`}
                    label=""
                                        checked={statusFjarde.upload_radio_min3}
                    onChange={e => onBoxClick(e)}
                    // name="upload_radio"
                    // checked={status.upload_radio}
                    // value="textarea"
                    // onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`fjarde_-2`}
                    name={`group-fjarde`}
                    label=""
                                        checked={statusFjarde.upload_radio_min2}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`fjarde_-1`}
                    name={`group-fjarde`}
                    label=""
                                        checked={statusFjarde.upload_radio_min1}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`fjarde_0`}
                    name={`group-fjarde`}
                    label=""
                                        checked={statusFjarde.upload_radio_0}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`fjarde_1`}
                    name={`group-fjarde`}
                    label=""
                                        checked={statusFjarde.upload_radio_1}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`fjarde_2`}
                    name={`group-fjarde`}
                    label=""
                                        checked={statusFjarde.upload_radio_2}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`fjarde_3`}
                    name={`group-fjarde`}
                    label=""
                                        checked={statusFjarde.upload_radio_3}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`fjarde_ia`}
                    name={`group-fjarde`}
                    label=""
                                        checked={statusFjarde.upload_radio_ia}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
              </Row>{" "}
            </Form.Group>{" "}
            <Form.Group required>
              <Row className="rowNew">
                <Col xs={6} md={3} >
                Klimatpåverkan
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`femte_-3`}
                    name={`group-femte`}
                    label=""
                                        checked={statusFemte.upload_radio_min3}
                    onChange={e => onBoxClick(e)}
                    // name="upload_radio"
                    // checked={status.upload_radio}
                    // value="textarea"
                    // onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`femte_-2`}
                    name={`group-femte`}
                    label=""
                                        checked={statusFemte.upload_radio_min2}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`femte_-1`}
                    name={`group-femte`}
                    label=""
                                        checked={statusFemte.upload_radio_min1}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`femte_0`}
                    name={`group-femte`}
                    label=""
                                        checked={statusFemte.upload_radio_0}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`femte_1`}
                    name={`group-femte`}
                    label=""
                                        checked={statusFemte.upload_radio_1}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`femte_2`}
                    name={`group-femte`}
                    label=""
                                        checked={statusFemte.upload_radio_2}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`femte_3`}
                    name={`group-femte`}
                    label=""
                    checked={statusFemte.upload_radio_3}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`femte_ia`}
                    name={`group-femte`}
                    label=""
                    checked={statusFemte.upload_radio_ia}
                    onChange={e => onBoxClick(e)}
                  />
                </Col>
              </Row>{" "}
            </Form.Group>{" "}


              {/* <ModalFormRowCheck title='Tid delprocess' groupnr='1'/>
   <ModalFormRowCheck title='Tid helhet' groupnr='2' onCheckboxClick={() => onCheckboxClick}/>
   <ModalFormRowCheck title='Kostnad delprocess' groupnr='3' onCheckboxClick={onCheckboxClick}/>
   <ModalFormRowCheck title='Kostnad helhet' groupnr='4' onCheckboxClick={onCheckboxClick}/>
   <ModalFormRowCheck title='Klimatpåverkan' groupnr='5' onCheckboxClick={onCheckboxClick}/> */}
  <Form style={{borderTop: "1px solid whitesmoke"}}>
  <Form.Group style={{marginTop:"1.5em"}} controlId="exampleForm.ControlTextarea1">
    <Form.Label>Kommentarer</Form.Label>
    <Form.Control as="textarea" rows="3" value={comment}
									onInput={(e) => setComment(e.target.value)}
								/>
  </Form.Group>
</Form>            

          </Container>
        </Modal.Body>
        <Modal.Footer> 
          {success}
        <Button type="submit" variant="success">Skicka</Button>{' '}
<Button onClick={props.handleClose} variant="outline-primary">Stäng</Button>
        </Modal.Footer>        
</Form></div>
</Draggable></Modal> 
     
      

    </>
  );
}

export default ModalTest;
