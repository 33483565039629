import React, { useState } from "react";
// import "../../App.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ModalForm from "./ModalForm";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

function ModalTest(props) {
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState({theTitle:'', upload_radio: false})
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [bedomning, setBedomning] = useState([{tidDelprocess:'', tidHelhet:'', kostnadDelprocess:'', kostnadHelhet: '', klimatpaverkan: ''}])

//  function onBoxClick(e) {
//     setStatus({ title: props.title, upload_radio: e.target.value })
//     props.onCheckboxClick({status})
//   }

//   const hidePopup = useCallback(
//     (event) => {
//         event.preventDefault();
//         setPopup(!popup);
//     }, []
// );

  return (
    <>
              <Form.Group required>
              <Row className="rowNew">
                <Col xs={6} md={3} >
                  {props.title}
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`${props.groupnr}_-3`}
                    name={`group-${props.groupnr}`}
                    label=""
                    // name="upload_radio"
                    // checked={status.upload_radio}
                    // value="textarea"
                    // onChange={e => onBoxClick(e)}
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`${props.groupnr}_-2`}
                    name={`group-${props.groupnr}`}
                    label=""
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`${props.groupnr}_-1`}
                    name={`group-${props.groupnr}`}
                    label=""
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`${props.groupnr}_0`}
                    name={`group-${props.groupnr}`}
                    label=""
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`${props.groupnr}_1`}
                    name={`group-${props.groupnr}`}
                    label=""
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`${props.groupnr}_2`}
                    name={`group-${props.groupnr}`}
                    label=""
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`${props.groupnr}_3`}
                    name={`group-${props.groupnr}`}
                    label=""
                  />
                </Col>
                <Col xs={10} md={1}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    id={`${props.groupnr}_ingenasikt`}
                    name={`group-${props.groupnr}`}
                    label=""
                  />
                </Col>
              </Row>{" "}
            </Form.Group>{" "}
    </>
  );
}

export default ModalTest;
