import React, { useState, useContext, useEffect } from 'react';
import { AccountContext } from './Accounts';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import XMark from './svg-saker/components/XMark';


export default () => {
    const [ status, setStatus ] = useState(false);
    const [ name, setName ] = useState("")

    const { getSession, logout } = useContext(AccountContext);

    useEffect(() => {
        getSession()
            .then(session => {
                console.log('Session:', session);
                setStatus(true);
                setName(session.name)
            })
            .catch(err=> console.log(err))
    }, []);

    return (<div>
        { status ? (<div><Navbar className="bg-light justify-content-end"> <Navbar.Text> {name} </Navbar.Text><div  className="button-signout"><Button variant="info" onClick={logout}>Logga ut</Button></div> </Navbar><XMark/></div>) : '' }

    </div>)
}