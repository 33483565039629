import React, { useState, useEffect, useContext } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js'
import { AccountContext } from './Accounts'
import Pool from '../UserPool';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    Redirect
  } from "react-router-dom";

export default () => {
        const [stage, setStage] = useState(1) // 1 = email stage, 2 = code stage
        const [email, setEmail] = useState('')
        const [code, setCode] = useState('')
        const [password, setPassword] = useState('')
        const [confirmPassword, setConfirmPassword] = useState('')
        const [ onSuccess, setOnSuccess] = useState('')

        const [loggedIn, setLoggedIn ] = useState(false);
        
        const { getSession } = useContext(AccountContext);


        var history = useHistory();

        
            useEffect(() => {
                getSession()
                    .then(() => { setLoggedIn(true);
            })
                    .catch(err=> console.log(err))      
            }, []);
        

        const onClickButton = () => {

            setTimeout(() =>
               
            window.location.reload()
            , 3000);

        }


        const getUser = () => {
            return new CognitoUser({
                Username: email.toLowerCase(),
                Pool
            })
        }
        
        const sendCode = event => {
            event.preventDefault();

            getUser().forgotPassword({
            onSuccess: data => {
                console.log('onSuccess: ', data)
            },
            onFailure: err => {
                console.log('onFailure:', err);
            },
            inputVerificationCode: data => {
                console.log('Input code:', data)
                setStage(2)
            }
            
            })
        }


        const resetPassword = event => {
            event.preventDefault();

            if (password !== confirmPassword){
                console.log('The password and confirmPassowrd are not the same');
                setOnSuccess(<p style={{color: "firebrick"}}>Lösenorden matchades inte. Försök igen!</p>)
                return;
            }

            getUser().confirmPassword(code, password, {
                onSuccess: data => {
                    console.log('onSuccess:', data)
                    setOnSuccess(<p style={{color: "green"}}>Nu har lösenordet återställts!</p>)
                    setTimeout(() =>
               
                    window.location.reload()
                    , 1500);
            

                },
                onFailure: err => {
                    console.error('onFailure:', err)
                    if(err.name === "LimitExceededException"){
                        setOnSuccess(<p style={{color: "firebrick"}}>För många försök. Försök igen om en stund.</p>)

                    }
                },

            })
        }
        
return (           
        <div>
            {(!loggedIn && stage === 1) && (
                <Form onSubmit={sendCode}>

                    <div className="wrapper-login-text">Glömt lösenord</div>

                    <Form.Label>Emailadress</Form.Label>
                    <Form.Control size="sm" value={email} onChange={event => setEmail(event.target.value)}/>
                    <div className="button-login-signup">

                    <Button variant="outline-info" type="submit"> Skicka verifikationskod </Button>
                    </div>

                </Form>
            )
            
            }
            {(!loggedIn && stage === 2) && (
                <Form onSubmit={resetPassword}>
                                        <div className="wrapper-login-text">Glömt lösenord</div>

                <Form.Label>Ett mejl har skickats till dig med en verifikationskod om kontot finns regisitrerat och verifierat hos oss. Ifall mejlet inte kommit fram till emailadressen, kolla då även skräpposten. Kontakta oss annars på: info@nikolaidev.com.<br/><br/>Verifikationskod:</Form.Label>
                <Form.Control value={code} onChange={event => setCode(event.target.value)}/>
                <Form.Label>Nytt lösenord:</Form.Label>
                <Form.Control type="password" value={password} onChange={event => setPassword(event.target.value)}/>
                <Form.Label>Upprepa nytt lösenord:</Form.Label>
                <Form.Control type="password" value={confirmPassword} onChange={event => setConfirmPassword(event.target.value)}/>
                {onSuccess}
                <div className="button-login-signup">
            

                <Button type="submit">Byt lösenord</Button>
                </div>

            </Form>
            )
            
            }
        </div>
    )
}