import React, { useState, useEffect, useContext } from 'react';
import { AccountContext } from './Accounts';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


export default () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loggedIn, setLoggedIn ] = useState(false);
  const [ anErrorOccured, setAnErrorOccured ] = useState('')


  const { getSession, authenticate } = useContext(AccountContext);


useEffect(() => {
    getSession()
        .then(() => { setLoggedIn(true);
})
        .catch(err=> console.log(err))      
}, []);


  const onSubmit = event => {
    event.preventDefault();

    authenticate(email, password)
      .then(data => {
        console.log('Logged in!', data);
        window.location.reload();

      })
      .catch(err => {
        console.error('Failed to login!', err);
        if (err.name === "UserNotConfirmedException")
        {
            setAnErrorOccured(<p style={{color: "firebrick", fontSize:"0.8em"}}>Detta kontot är inte verifierat. Om ni har registrerat er men inte fått ett mejl med länk, kontakta oss på info@nikolaidev.com.</p>)
        }
        else if (err.name === "NotAuthorizedException") {
            setAnErrorOccured(<p style={{color: "firebrick", fontSize:"0.8em"}}>Inkorrekt emailadress eller lösenord.</p>)
            
        }

        else{
            setAnErrorOccured(<p style={{color: "firebrick", fontSize:"0.8em"}}>Ett fel har uppstått.</p>)

        }
      })
  };

  return (

    
    <div> 
        { !loggedIn && (
      <Form onSubmit={onSubmit}> 
          <div className="wrapper-login-text">Logga in</div>
          <Form.Label>Email</Form.Label>
        <Form.Control required size="sm" type="text"
          value={email}
          onChange={event => setEmail(event.target.value)}
        />
        <Form.Label>Lösenord</Form.Label>
        <Form.Control required size="sm" type="password"
          value={password}
          onChange={event => setPassword(event.target.value)}
        />
        <div className="button-login-signup">
            {anErrorOccured}

        <Button size="sm" variant="info" type='submit' block>Logga in</Button>
        </div>
      </Form>)}
    </div>
  );
};